<template>
  <div>
    <div class="fk-prose mx-auto text-center !max-w-lg">
      <h2>{{ $t("block.workAtSlider.heading") }}</h2>
      <p>{{ $t("block.workAtSlider.paragraph") }}</p>
    </div>
  </div>

  <div class="!max-w-none !p-0">
    <FkStrapiCollection
      :collection="`company`"
      :pagination="{
        page: 1,
        pageSize: 12,
      }"
      :use-filter-bar="false"
      :use-pagination="false"
      sort="createdAt:desc"
      :filters="{
        $and: [
          {
            workAtUrl: {
              $notNull: true,
            },
          },
          {
            workAtUrl: {
              $ne: '',
            },
          },
        ],
        logo: {
          $notNull: true,
        },
      }"
    >
      <template #items="{ data }">
        <template v-if="data && Array.isArray(data.data)">
          <Swiper :modules="[SwiperAutoplay]" v-bind="swiperSettings">
            <SwiperSlide
              v-for="item in data.data"
              :key="`${block.id}-slide-${item.id}`"
              class="!w-60 mx-2 lg:!w-80 lg:mx-4"
            >
              <NuxtLink
                :to="item.attributes.workAtUrl"
                class="block aspect-video bg-white p-12 rounded select-none shadow-none hover:shadow-xl transition-shadow my-base"
                :title="item.attributes.title"
                target="_blank"
                rel="noopener"
              >
                <NuxtPicture
                  v-if="item.attributes.logo && item.attributes.logo.data"
                  :provider="item.attributes.logo.data.attributes.provider"
                  :src="item.attributes.logo.data.attributes.hash"
                  :width="item.attributes.logo.data.attributes.width"
                  :height="item.attributes.logo.data.attributes.height"
                  :sizes="'xs:100vw sm:100vw md:50vw lg:33vw xl:33vw xxl:33vw'"
                  :preload="false"
                  :loading="'lazy'"
                  :alt="item.attributes.logo.data.attributes.alternativeText"
                  class=""
                  :img-attrs="{
                    class: 'w-full h-full object-contain',
                  }"
                />
              </NuxtLink>
            </SwiperSlide>
          </Swiper>
        </template>
      </template>
    </FkStrapiCollection>
  </div>
  <!-- TODO: /werken-bij should be i18n-->
  <div class="text-center">
    <NuxtLink to="/werken-bij" class="btn">{{
      $t("block.workAtSlider.button")
    }}</NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiBlock } from "@flyingkiwi/nuxt-components";

defineProps<{
  block: StrapiBlock;
}>();

const swiperSettings = computed(() => {
  const defaults = {
    loop: true,
    centeredSlides: true,
    slidesPerView: "auto",
    spaceBetween: 0,
    grabCursor: true,
    autoplay: {
      delay: 1500,
      pauseOnMouseEnter: true,
    },
  };
  return Object.assign(defaults);
});
</script>

<style lang="css" scoped></style>
